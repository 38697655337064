<template>
  <div class="addSchool">
    <el-scrollbar class="elScroll">
      <el-form label-position="right" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="170px" class="demo-ruleForm" :disabled="isRead">
        <el-form-item label="平台名称" prop="name">
          <el-input v-model="ruleForm.name" @input="trimLR('name')"></el-input >
        </el-form-item>
        <el-form-item label="学校名称" prop="abbreviation">
          <el-input v-model="ruleForm.abbreviation" @input="trimLR('abbreviation')" maxlength="30"></el-input>
          <span>（请填写学生学籍所属学校，建议限制字数为12字以内）</span>
        </el-form-item>
        <el-form-item label="学校类型" prop="type">
          <el-select v-model="ruleForm.type" placeholder="学校类型" filterable>
            <el-option
              v-for="item in schoolTypeData"
              :key="item.label"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校性质">
          <el-select v-model="ruleForm.nature" placeholder="学校类型" filterable>
            <el-option
              v-for="item in schoolNatureData"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择地区" prop="regionId">
          <!-- <el-cascader
          v-model="ruleForm.regionId"
          :options="options"
          @change="handleChange"></el-cascader> -->
          <template v-if="type == 0 || type == 4 || type == 5">
            <el-select class="pub_select" v-model="pro_value" placeholder="请选择省" filterable clearable @change="getCities">
              <el-option
                v-for="item in proviceData"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
            <el-select class="pub_select cities" v-model="ruleForm.regionId" placeholder="请选择市" filterable clearable>
              <el-option
                v-for="item in citiesData"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </template>
          <template v-else>
            <el-input class="region_input" v-model="ruleForm.provinceName"></el-input>
            <el-input class="region_input" v-model="ruleForm.cityName"></el-input>
          </template>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="ruleForm.address" @input="trimLR('address')"></el-input>
        </el-form-item>
        <el-form-item label="学校编码" prop="encoding">
          <el-input v-model="ruleForm.encoding" @blur="regEncodeing" :disabled='type == 5' @input="trimLR('encoding')"></el-input>
          <span>（学校官网域名编码）</span>
        </el-form-item>
        <el-form-item label="缴款单位" prop="payer">
          <el-input v-model="ruleForm.payer" @input="trimLR('payer')"></el-input>
        </el-form-item>
        <el-form-item label="学校网址" prop="website">
          <el-input v-model="ruleForm.website" @input="trimLR('website')"></el-input>
        </el-form-item>
        <el-form-item label="学校logo" prop="logoUrl">
          <el-button v-if="!ruleForm.logoUrl" @click="getLocalPic(1)">选择文件</el-button>
          <template v-else>
            <el-image class="pic_show" :src="ruleForm.logoUrl" alt="" @click="getLocalPic(1)"></el-image>
            <span class="preview" @click="watchEvent([ruleForm.logoUrl])">在线预览</span>
          </template>
          <span>图片格式为：高70像素，宽不限，透明底png,jpg图片</span>
        </el-form-item>
        <el-form-item label="学校内页LOGO" prop="sealUrl">
          <el-button v-if="!ruleForm.sealUrl" @click="getLocalPic(2)">选择文件</el-button>
          <template v-else>
            <el-image class="pic_show" :src="ruleForm.sealUrl" alt="" @click="getLocalPic(2)"></el-image>
            <span class="preview" @click="watchEvent([ruleForm.sealUrl])">在线预览</span>
          </template>
          <span>图片格式为：高70像素，宽不限，透明底png,jpg图片</span>
        </el-form-item>
        <el-form-item label="学校登录页LOGO" prop="loginLogoUrl">
          <el-button v-if="!ruleForm.loginLogoUrl" @click="getLocalPic(3)">选择文件</el-button>
          <template v-else>
            <el-image class="pic_show" :src="ruleForm.loginLogoUrl" alt="" @click="getLocalPic(3)"></el-image>
            <span class="preview" @click="watchEvent([ruleForm.loginLogoUrl])">在线预览</span>
          </template>
          <span>图片格式为：高46像素，宽336像素，透明底png,jpg图片</span>
        </el-form-item>
        <el-form-item label="合同状态">
          <el-select v-model="ruleForm.cooperationStatus" placeholder="合同状态">
            <el-option label="试用中" value="TRIAL"></el-option>
            <el-option label="合约中" value="RUN"></el-option>
            <el-option label="已过期" value="EXPIRE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="招生类型" prop="enrollmentType">
          <el-select v-model="ruleForm.enrollmentType" placeholder="招生类型" :disabled='type == 5'>
            <el-option label="单季招生" :value="1"></el-option>
            <el-option label="双季招生" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收费单价" prop="unitPrice">
          <el-input class="payModule" v-model.number="ruleForm.unitPrice" maxlength="8" @input="trimLR('unitPrice')"></el-input> 元/人
        </el-form-item>
        <el-form-item label="是否支持学生自主缴费" prop="personalPayFlag">
          <el-radio-group v-model="ruleForm.personalPayFlag">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客服设置">
          <el-select v-model="ruleForm.customerConfigId" placeholder="客服设置" @focus="changeCus">
            <el-option v-for="(item, index) in customerList" :key="index" :label="item.name || '/'" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收费说明" prop="chargeDescription">
          <el-input v-model="ruleForm.chargeDescription" @input="trimLR('chargeDescription')"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" maxlength="255" show-word-limit v-model="ruleForm.remarks"></el-input>
        </el-form-item>
        <el-form-item label="学校简介" prop="briefIntroduction">
          <!-- <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" show-word-limit v-model="ruleForm.briefIntroduction"></el-input> -->
          <!-- <vue-ueditor-wrap v-model="ruleForm.briefIntroduction" :config="myConfig" :destroy="true" @ready="ready" @beforeInit="addCustomButtom"></vue-ueditor-wrap> -->
          <vueEditor :editConfig="editConfig" :editorContent.sync="ruleForm.briefIntroduction"></vueEditor>
        </el-form-item>
      </el-form>
      <div class="opreaHang" v-if="type == 0 || type == 4">
        <el-button type="primary" @click="submitForm('ruleForm')">提交审核</el-button>
        <el-button @click="goBack">返回</el-button>
      </div>
      <div class="opreaHang" v-else-if="type == 1">
        <el-button type="primary" @click="examineEvent('YES')" v-allow="'sys_school_audit'">审核通过</el-button>
        <el-button type="warning" @click="examineEvent('NO')" v-allow="'sys_school_audit'">审核不通过</el-button>
      </div>
      <div class="opreaHang" v-else-if="type == 5">
        <el-button type="primary" @click="editSub('ruleForm')">保 存</el-button>
        <el-button @click="goBack">返回</el-button>
      </div>
      <div class="opreaHang" v-else>
        <el-button type="primary" @click="enterEvent">确 认</el-button>
      </div>
    </el-scrollbar>

    <el-image-viewer
      v-if="showViewer"
      :on-close="watchEvent"
      :url-list="voucherUrlsPic"
    />

    <input style="display: none;" type="file" @change="picChange()" ref="loadPic" accept="image/*" />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import vueEditor from '../commonModule/vueEditor'
import VueUeditorWrap from 'vue-ueditor-wrap'
import way from '../../api/encapsulation'
const OSS = require('ali-oss');
export default {
  name: 'addSchool',
  components: {
    vueEditor,
    VueUeditorWrap,
    ElImageViewer
  },
  data() {
    let regEncodeing = this.regEncodeing;
    async function checkEncoding(rule, value, callback) {
      let encodeType = await regEncodeing()
      if(encodeType && value) {
        return callback(new Error('该学校编码已存在'));
      } else {
        callback();
      }
    }
    var validQC = (rule, value, callback) => {
      callback(way.regChinese(value))
    };
    return {
      isRead: false,
      type: 0,
      ruleForm: {
        name: '',
        regionId: '',
        address: '',
        abbreviation: '',
        encoding: '',
        payer: '',
        website: '',
        logoUrl: '',
        sealUrl: '',
        loginLogoUrl: '',
        cooperationStatus:'TRIAL',
        type:'0',
        unitPrice: '',
        chargeDescription: '',
        remarks: '',
        briefIntroduction: '',
        personalPayFlag: 0,
        enrollmentType: 1,
        nature: 1,
        customerConfigId: null,
      },
      pro_value: '',
      city_value: '',
      proviceData: [], // 省数据
      citiesData: [], // 市数据
      schoolTypeData: [], // 学校类型
      customerList: [], // 客服列表
      schoolNatureData: [
        {
          id: 1,
          name: '商用'
        },
        {
          id: 2,
          name: '演示'
        },
      ], // 学校性质
      Tid: '', // 租客id
      rules: {
        name: [
          { required: true, message: '请输入学校名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        abbreviation: [
          { required: true, message: '请输入学校简称', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择学校类型', trigger: ['blur', 'change'] }
        ],
        enrollmentType: [
          { required: true, message: '请选择招生类型', trigger: ['blur', 'change'] }
        ],
        personalPayFlag: [
          { required: true, message: '请选择是否支持学生自主缴费', trigger: ['blur', 'change'] }
        ],
        regionId: [
          { required: true, message: '请选择地区', trigger: ['blur', 'change'] }
        ],
        unitPrice: [
          { required: true, message: '请输入收费单价', trigger: 'blur' },
          { type: 'number', message: '收费单位必须为数字值'}
        ],
        encoding: [
          { required: true, message: '请输入学校编码', trigger: 'blur' },
          {
            validator: checkEncoding,
            trigger: 'blur'
          },
          { validator: validQC, trigger: ["blur", "change"] }
        ],
        website: [
          { validator: validQC, trigger: ["blur", "change"] }
        ]
        // logoUrl: [
        //   { required: true, message: '请选择学校logo', trigger: 'blur' }
        // ]
      },
      options: [],
      urlType: 1,
      voucherUrlsPic: [],
      isEdit: false,
      showViewer: false,
      editConfig: {
        zIndex: 1,
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: 'http://35.201.165.105:8000/controller.php',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli2.0 生成的项目，通常不需要设置该选项，默认是：UEDITOR_HOME_URL: '/static/UEditor/'
        //但是 vue cli 3.x 生成的项目中，默认值是 process.env.BASE_URL + 'UEditor/'，如果项目不是部署在根目录下，则需要自己配置路径，我的项目是在根目录下，手动下载的UEditor放在了public目录下
        UEDITOR_HOME_URL: '/UE/',
      },
      editorModule: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.query.hasOwnProperty('status') || newVal.params.hasOwnProperty('status')) {
          this.type = newVal.query.status || newVal.params.status;
          console.log(this.type, 'this,type')
          if(newVal.params.hasOwnProperty('id')) {
            this.Tid = newVal.params.id;
            this.queryIdEvent(this.Tid);
          }
          if(this.type < 4) {
            this.isRead = true;
          }
          if(this.type >= 4) {
            this.isEdit = true;
          }
        }
      }
    }
  },
  created() {
    this.getProviceEvent();
    this.getSchoolType();
  },
  methods: {
    trimLR(name) {
      this.ruleForm[name] = way.clearSpace(this.ruleForm[name]);
    },
    ready(editorInstance) {
      // console.log(`编辑器实例${editorInstance.key}: `, editorInstance)
    },
    addCustomButtom(editorId) {
      var that = this;
      window.UE.registerUI('simpleupload insertvideo', function (editor, uiName) {
        // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
        editor.registerCommand(uiName, {
          execCommand: function () {
            editor.execCommand('inserthtml', ``);
          }
        })
        let btn = new window.UE.ui.Button({
            // 按钮的名字
            name: uiName,
            // 提示
            title: uiName == 'simpleupload' ? '单图上传' : '视频上传',
            // 点击时执行的命令
            onclick: function () {
              let upLoadType = uiName == 'simpleupload' ? 'img' : 'video';
              that.editorModule = editor;
              that.getLocalPic(upLoadType);
            }
          })

        // 当点到编辑内容上时，按钮要做的状态反射
        editor.addListener('selectionchange', function () {
          // var state = editor.queryCommandState(uiName);
          // if (state === -1) {
          //   btn.setDisabled(true);
          //   btn.setChecked(false);
          // } else {
          //   btn.setDisabled(false);
          //   btn.setChecked(state);
          // }
        })

        // 因为你是添加 button，所以需要返回这个 button
        return btn;
      }, 46 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */, editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */)
    },
    // 通过id查询
    queryIdEvent(id) {
      this.$request.idQuery({ id }, res => {
        if(res.code == 0) {
          this.ruleForm.name = res.data.name;
          this.ruleForm.regionId = res.data.regionId;
          this.ruleForm.address = res.data.address;
          this.ruleForm.abbreviation = res.data.abbreviation;
          this.ruleForm.encoding = res.data.encoding;
          this.ruleForm.payer = res.data.payer;
          this.ruleForm.website = res.data.website;
          this.ruleForm.logoUrl = res.data.logoUrl;
          this.ruleForm.sealUrl = res.data.sealUrl;
          this.ruleForm.loginLogoUrl = res.data.loginLogoUrl;
          this.ruleForm.enrollmentType = res.data.enrollmentType;
          this.ruleForm.cooperationStatus = res.data.cooperationStatus;
          this.ruleForm.type = String(res.data.type);
          this.ruleForm.unitPrice = res.data.unitPrice;
          this.ruleForm.chargeDescription = res.data.chargeDescription;
          this.ruleForm.remarks = res.data.remarks;
          this.ruleForm.briefIntroduction = res.data.briefIntroduction;
          this.ruleForm.personalPayFlag = res.data.personalPayFlag;
          this.ruleForm.nature = res.data.nature;
          this.ruleForm.customerConfigId = res.data.customerConfigId;
          this.newGetCode(res.data);
          if(this.type == 1 || this.type == 2 || this.type == 3) {
            this.ruleForm.provinceName = res.data.provinceName;
            this.ruleForm.cityName = res.data.cityName;
          }
        }
      })
    },
    watchEvent(urlArr) {
      this.showViewer = !this.showViewer;
      if(this.showViewer) {
        this.voucherUrlsPic = urlArr;
      }
    },
    newGetCode(form) {
      if(this.type == 4 || this.type == 5 && form.regionId) {
        if(this.proviceData.length == 0) {
          setTimeout(()=>{
            this.newGetCode(form);
          },500)
          return;
        }
        let index = this.proviceData.findIndex(item => item.name == form.provinceName);
        this.pro_value = this.proviceData[index].code;
        // this.city_value = Number(form.regionId);
        this.getCities();
      }
    },
    getRenId() {
      let c_index = this.citiesData.findIndex(item => item.name == this.city_value);
      this.ruleForm.regionId = this.citiesData[c_index].id;
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getPermissionProvinces({}, res => {
        if (res.code == 0) {
          this.proviceData = res.data;
        }
      })
    },
    // 获取市
    getCities() {
      if(!this.pro_value) {
        this.citiesData = [];
        this.ruleForm.regionId = ''
        this.ruleForm.customerConfigId = null
        this.customerList = []
        return;
      }
      if(this.pro_value && this.ruleForm.regionId) {
        if(this.isEdit) {
          this.isEdit = false;
        } else {
          this.ruleForm.regionId = ''
          this.ruleForm.customerConfigId = null
          this.customerList = []
        }
      }
      this.getCusServiceObjList()
      let obj = {
        parentCode: this.pro_value
      }
      this.$request.getCity(obj, res =>{
        if(res.code == 0) {
          this.citiesData = res.data;
        }
      })
    },
    // 获取学校类型
    getSchoolType() {
      this.$request.getSchoolTypeDown(res => {
        this.schoolTypeData = res.data;
      })
    },
    // 验证学校编码是否存在
    regEncodeing() {
      if(this.type == 0 || this.type == 4 || this.type == 5) return;
      return new Promise(resolve=>{
          this.$request.regEncode({encoding: this.ruleForm.encoding}, res => {
          if(res.code == 0) {
            resolve(res.data);
          }
        })
      })
    },
    // 获取客服下拉
    getCusServiceObjList() {
      if (!this.pro_value) return;
      let provinceId = this.proviceData.filter(item => item.code == this.pro_value)[0].id
      this.$request.cusServiceObjList({provinceId}).then(res => {
        if (res.data.code == 0) {
          this.customerList = res.data.data
          if(this.type == '0' && this.customerList.length != 0) this.ruleForm.customerConfigId = this.customerList[this.customerList.length - 1].id
        }
      })
    },
    picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      let isFileType = files.type
      // if(isFileType != 'image/png' || isFileType != 'image/jpeg') {
      //   this.Warn('上传图片只能是 PNG 或 JPG 格式!');
      //   return;
      // }
      if (files.name.indexOf('+') != -1) {
        this.Warn('文件名内不能带有+号');
        return;
      }
      let that = this;
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        //加载图片获取图片真实宽度和高度
        var image = new Image();
        image.onload=function(){
            var width = image.width;
            var height = image.height;
            // if(width != 500 && height != 500) {
            //   that.Warn('上传图片的尺寸只能为500X500')
            //   return;
            // }
            that.upLoadEvent(files);
        };
        image.src = data;
      };
      reader.readAsDataURL(files);
    },
    async upLoadEvent(file) {
      this.$request.uploadPic(file, res=>{
        if(res.code == 0) {
          let ossUrl = res.data;
          if(this.urlType == 1) {
            this.ruleForm.logoUrl = ossUrl;
          } else if(this.urlType == 2) {
            this.ruleForm.sealUrl = ossUrl;
          } else if(this.urlType == 3) {
            this.ruleForm.loginLogoUrl = ossUrl;
          } else if(this.urlType == 'img') {
            this.editorModule.execCommand('inserthtml', '<img src="'+ ossUrl +'" width="600"/>');
          } else if(this.urlType == 'video') {

          }
        }
      })
    },
    getLocalPic(index) {
      this.urlType = index;
      this.$refs.loadPic.value= '';
      this.$refs.loadPic.click();
    },
    handleChange(value) {
    },
    // 提交审核
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.type == 0) {
            this.addStudentEvent();
          } else if(this.type == 4) {
            this.nextSub();
          }
        } else {
          return false;
        }
      });
    },
    // 添加学校
    addStudentEvent() {
      let ruleForm = this.ruleForm;
      if(String(ruleForm.unitPrice) == '0') {
        this.Warn('收费单价不能为0');
        return;
      }
      this.$request.addSchoolInfo(ruleForm, res => {
        if(res.code == 0){
          this.Success(res.msg);
          this.goBack();
        }
      })
    },
    // 确认
    enterEvent() {
      this.goBack();
    },
    // 失败后再次审核
    nextSub() {
      let ruleForm = this.ruleForm;
      ruleForm.id = this.Tid;
      this.$request.resubmitNew(ruleForm).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.goBack();
        }
      }).catch(err=>{
        if(err.response.data.code == 1){
          this.Error(err.response.data.msg)
        }
      })
    },
    // 审核
    examineEvent(val) {
      let obj = {
        examineEnum: val,
        id: this.Tid
      }
      this.$request.examine(obj).then(res=>{
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          this.goBack();
        }
      }).catch(err=>{
        if(err.response.data.code == 1){
          this.Error(err.response.data.msg)
        }
      })
    },
    // 编辑
    editSub(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ruleForm = this.ruleForm;
          ruleForm.id = this.Tid;
          if(String(ruleForm.unitPrice) == '0') {
            this.Warn('收费单价不能为0');
            return;
          }
          this.$request.editSchoolInfo(ruleForm).then(res => {
            if(res.data.code == 0) {
              this.Success(res.data.msg)
              this.goBack();
            }
          }).catch(err=>{
            if(err.response.data.code == 1){
              this.Error(err.response.data.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 返回上一级
    goBack() {
      this.$router.go(-1);
    },
    changeCus() {
      if (!this.pro_value) {
        this.Warn('请选择省份')
        return;
      }
    },
  },
}
</script>

<style lang='less'>
  .addSchool {
    background-color: #fff;
    box-sizing: border-box;
    padding-top: 40px;
    height: calc(~'100% - 68px');
    // overflow: scroll;
    .elScroll {
      width: 100%;
      height: 100%;
    }
    .el-form {
      width: 800px;
      margin: 0 auto;
      .el-form-item:nth-last-of-type(4) {
        .el-input {
          width: 200px;
        }
      }
      .el-form-item {
        .el-form-item__content {
          & > span {
            display: block;
          }
        }
      }
      .region_input {
        width: 190px;
        &:nth-of-type(1) {
          margin-right: 20px;
        }
      }
      .pub_select {
        width: 180px;
      }
      .cities {
        margin-left: 20px;
      }
      .pic_show {
        display: inline-block;
        // width: 60px;
        height: 60px;
      }
      .preview {
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          color: #1282FF;
        }
      }
      .payModule {
        width: 50%;
      }
    }
    .opreaHang {
      text-align: center;
      margin-bottom: 40px;
    }
  }
</style>
